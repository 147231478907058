<template>
  <div class="footer">
    <div class="footer__inner">
      <ul class="footer__items">
        <li
          v-for="(item, index) in items"
          :key="index"
          class="footer__item"
        >
          <router-link
            :to="item.link"
            class="footer__link"
          >
            {{ item.text }}
          </router-link>
        </li>
      </ul>
      <div
        class="footer__copyright"
        :class="classCopyrightAlign"
      >
        {{ copyright.text }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import { FooterOptions } from 'vue3-types'

export default defineComponent({
  props: {
    footerOptions: {
      type: Object as PropType<FooterOptions>,
      default: () => ({
        footerItems: [],
        copyright: {
          align: 'center'
        }
      })
    }
  },
  setup (props) {
    const items = computed(
      () => props.footerOptions.footerItems ?? []
    )
    const copyright = computed(
      () => props.footerOptions.copyright ?? {}
    )
    const classCopyrightAlign = computed(
      () => {
        const align = props.footerOptions.copyright.align ?? 'center'
        return `footer__copyright--${align}`
      }
    )
    return {
      items,
      copyright,
      classCopyrightAlign
    }
  }
})
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  min-height: 50px;
  background-color: var(--footer-color);
  z-index: 10;
  &__inner {
    padding: 10px 20px;
    text-align: center;
  }
  &__items {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 4px;
    list-style-type: none;
  }
  &__item {
    padding-right: 20px;
    font-size: var(--contents-text-size);
    &:last-child {
      padding-right: 0;
    }
  }
  &__link {
    text-decoration: none;
    &:visited, :active, :hover {
      color: var(--font-color);
    }
  }
  &__copyright {
    display: flex;
    padding-top: 4px;
    font-size: var(--contents-text-small-size);
    &--start {
      justify-content: flex-start;
    }
    &--center {
      justify-content: center;
    }
    &--end {
      justify-content: flex-end;
    }
  }
}
</style>
