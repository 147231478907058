<template>
  <div
    id="howto"
    class="howto"
  >
    <div class="howto__wrapper">
      <h2
        class="howto__title"
      >
        マグロの美味しい食べ方
      </h2>
      <div
        class="howto__section"
      >
        <h3
          class="howto__section--title"
        >
          冷凍マグロの解凍方法（通常）
        </h3>
        <ul class="howto__list">
          <li
            v-for="(item, index) in state.list"
            :key="index"
            class="howto__list-item"
          >
            {{ item }}
          </li>
        </ul>
      </div>
      <div
        class="howto__section"
      >
        <h3
          class="howto__section--title"
        >
          冷凍マグロの解凍方法（時間がない場合）
        </h3>
        <ul class="howto__list">
          <li
            v-for="(item, index) in state.listSimple"
            :key="index"
            class="howto__list-item"
          >
            {{ item }}
          </li>
        </ul>
      </div>
      <div
        class="howto__section"
      >
        <h3
          class="howto__section--title"
        >
          サク取りの方法
        </h3>
        <div class="howto__cut">
          <div
            class="howto__image-frame--left"
          >
            <img
              :src="state.images.sample1"
              class="howto__image"
            >
          </div>
          <div
            class="howto__image-frame--right"
          >
            <img
              :src="state.images.sample2"
              class="howto__image"
            >
          </div>
        </div>
        <ul class="howto__list">
          <li
            v-for="(item, index) in state.listCut"
            :key="index"
            class="howto__list-item"
          >
            {{ item }}
          </li>
        </ul>
        <div class="howto__note">
          上記はあくまで目安ですのでこの通りではなくても美味しく食べて頂けます
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue'

export default defineComponent({
  setup () {
    const state = reactive({
      list: [
        '温塩水を作る(40℃くらいのお湯1リットルに対して塩大さじ2杯)',
        'マグロを温塩水に沈めて表面の切り粉を綺麗に洗う',
        '5分〜10分そのまま解凍',
        '流水で表面の塩水を軽く洗い流す',
        'キッチンペーパーで水分をふき取る',
        'キッチンペーパーを巻いて冷蔵庫で2時間ほど解凍',
        'キッチンペーパーの上からラップを巻いて冷蔵庫で半日寝かす'
      ],
      listSimple: [
        'マグロをぬるま湯で洗う',
        'キッチンペーパーで水分をふき取る',
        '3〜4時間常温解凍',
        '完全に溶けたらキッチンペーパーを巻いて冷蔵庫で半日寝かす'
      ],
      listCut: [
        '大きなサクを食べやすい大きさのサクに切り分ける',
        '包丁は筋に対して垂直に入れる'
      ],
      images: {
        sample1: require('@/assets/images/howto/howto_01.jpg'),
        sample2: require('@/assets/images/howto/howto_02.jpg')
      }
    })
    return {
      state
    }
  }
})
</script>

<style lang="scss" scoped>
.howto {
  width: 100%;
  &__wrapper {
    width: var(--contents-width);
    margin: 0 auto;
    padding-bottom: 50px;
    @include mq() {
      width: 100%;
      padding: 0 var(--paddig-side-space-sp) 50px;
    }
  }
  &__title {
    padding-bottom: 30px;
    font-size: var(--contents-title-size);
    font-weight: bold;
    color: var(--font-title-color);
  }
  &__section {
    width: 100%;
    &--title {
      padding-bottom: 20px;
      font-size: 20px;
      font-weight: bold;
      color: #333;
      @include mq() {
        font-size: 16px;
      }
    }
    &--title-sub {
      padding-bottom: 20px;
      font-size: 18px;
      font-weight: bold;
      color: #333;
    }
  }
  &__list {
    padding-left: 20px;
    padding-bottom: 30px;
  }
  &__list-item {
    font-size: var(--contents-text-size);
    line-height: 1.8rem;
    list-style-type: decimal;
    @include mq() {
      line-height: 1.5rem;
    }
  }
  &__cut {
    display: flex;
    padding-bottom: 20px;
    @include mq() {
      flex-direction: column;
    }
  }
  &__image-frame {
    &--left {
      width: 50%;
      padding-right: 10px;
      @include mq() {
        width: 100%;
        padding-right: 0;
        padding-bottom: 20px;
      }
    }
    &--right {
      width: 50%;
      padding-left: 10px;
      @include mq() {
        width: 100%;
        padding-left: 0;
      }
    }
  }
  &__image {
    width: 100%;
    object-fit: contain;
  }
  &__note {
    font-size: var(--contents-text-size);
    line-height: 1.5em;
    &::before {
      content: "※"
    }
  }
}
</style>
