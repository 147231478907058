
import { defineComponent, reactive } from 'vue'

export default defineComponent({
  setup () {
    const state = reactive({
      twitter: {
        logo: require('@/assets/images/contact/twitter.png'),
        text: 'twitter',
        link: 'https://twitter.com/maguro_uokane'
      },
      qr: {
        src: require('@/assets/images/contact/qr.jpg')
      }
    })
    return {
      state
    }
  }
})
