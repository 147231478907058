<template>
  <div
    id="products"
    class="products"
  >
    <div class="products__wrapper">
      <h2
        class="products__title"
      >
        取扱商品
      </h2>
      <div class="products__contents">
        <ul class="products__lists">
          <li
            v-for="(item, index) in state.list"
            :key="index"
            class="products__list"
          >
            {{ item }}
          </li>
        </ul>
        <div class="products__note">
          時期により取り扱いのないものもございます
        </div>
      </div>
      <div>
        <h3 class="products__reference--title">
          参考商品
        </h3>
        <div class="products__reference--lists">
          <div
            v-for="(item, index) in state.images"
            :key="index"
            :class="classReferenceList(index)"
          >
            <div
              class="products__reference--name"
            >
              {{ item.name }}
            </div>
            <div>
              <img
                :src="item.img"
                :alt="item.alt"
                class="products__reference--img"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue'

export default defineComponent({
  setup () {
    const state = reactive({
      list: [
        '生本マグロ(天然) ',
        '生インドマグロ(天然) ',
        '生メバチマグロ',
        '生キハダマグロ',
        '冷凍本マグロ(天然・蓄養)',
        '冷凍インドマグロ(天然)',
        '冷凍メバチマグロ',
        '生マカジキ',
        '冷凍マカジキ'
      ],
      images: [
        {
          name: '長崎産本マグロ',
          img: require('@/assets/images/products/honma_nagasaki.jpg'),
          alt: '長崎産本マグロ'
        },
        {
          name: 'ニュージーランド産インドマグロ',
          img: require('@/assets/images/products/india_nz.jpg'),
          alt: 'ニュージーランド産インドマグロ'
        },
        {
          name: '宮崎油津のキハダ上物',
          img: require('@/assets/images/products/kihada_miyazaki.jpg'),
          alt: '宮崎油津のキハダ上物'
        },
        {
          name: '和歌山勝浦産マカジキ',
          img: require('@/assets/images/products/makajiki_wakayama.jpg'),
          alt: '和歌山勝浦産マカジキ'
        }
      ]
    })
    const classReferenceList = (index: number) => {
      const blockEl = 'products__reference--list'
      return index % 2 === 0 ? `${blockEl}-left` : `${blockEl}-right`
    }
    return {
      state,
      classReferenceList
    }
  }
})
</script>

<style lang="scss" scoped>
.products {
  width: 100%;
  &__wrapper {
    width: var(--contents-width);
    margin: 0 auto;
    padding-bottom: 50px;
    @include mq() {
      width: 100%;
      padding: 0 var(--paddig-side-space-sp) 50px;
    }
  }
  &__title {
    padding-bottom: 30px;
    font-size: var(--contents-title-size);
    font-weight: bold;
    color: var(--font-title-color);
  }
  &__contents {
    font-size: var(--contents-text-size);
    padding-bottom: 50px;
  }
  &__lists {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 30px;
  }
  &__list {
    width: 33%;
    line-height: 2.5rem;
    font-size: 16px;
    @include mq() {
      width: 100%;
      font-size: var(--contents-text-size);
      line-height: 1.5rem;
    }
  }
  &__reference {
    &--title {
      padding-bottom: 10px;
      font-size: 20px;
      font-weight: bold;
      color: #333;
    }
    &--lists {
      display: flex;
      flex-wrap: wrap;
    }
    &--list-left {
      width: 50%;
      padding: 20px 20px 0 0;
      @include mq() {
        width: 100%;
        padding: 20px 0 0 0;
      }
    }
    &--list-right {
      width: 50%;
      padding: 20px 0 0 20px;
      @include mq() {
        width: 100%;
        padding: 20px 0 0 0;
      }
    }
    &--name {
      font-size: 16px;
      padding-bottom: 10px;
    }
    &--img {
      width: 100%;
      object-fit: contain;
    }
  }
  &__note {
    font-size: var(--contents-text-size);
    &::before {
      content: "※"
    }
  }
}
</style>
