
import { defineComponent, PropType, reactive, computed, onMounted } from 'vue'
import { HeaderOptions } from 'vue3-types'
import smoothscroll from 'smoothscroll-polyfill'

export default defineComponent({
  props: {
    headerOptions: {
      type: Object as PropType<HeaderOptions>,
      default: () => ({
        headerItems: []
      })
    },
    isSp: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const state = reactive({
      titleImage: null
    })
    const title = computed(
      () => props.headerOptions.headerTitle ?? {}
    )
    const items = computed(
      () => props.headerOptions.headerItems ?? []
    )
    onMounted(() => {
      smoothscroll.polyfill()
    })
    const scroll = (id: string) => {
      const el = document.getElementById(id)
      if (el) {
        const rect = el.getBoundingClientRect().top
        const offset = window.pageYOffset
        const gap = props.isSp ? 104 : 64 // header height
        const target = rect + offset - gap
        window.scrollTo({
          top: target,
          behavior: 'smooth'
        })
      }
    }
    return {
      state,
      title,
      items,
      scroll
    }
  }
})
