<template>
  <div class="layout">
    <AppHeader
      :header-options="headerOptions"
      :is-sp="isSp"
    />
    <router-view />
    <AppFooter
      :footer-options="footerOptions"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import { HeaderOptions, FooterOptions } from 'vue3-types'

export default defineComponent({
  components: {
    AppHeader,
    AppFooter
  },
  props: {
    headerOptions: {
      type: Object as PropType<HeaderOptions>,
      default: () => ({})
    },
    footerOptions: {
      type: Object as PropType<FooterOptions>,
      default: () => ({})
    },
    isSp: {
      type: Boolean,
      default: false
    }
  }
})
</script>

<style lang="scss" scoped>
.layout {
  position: relative;
  min-height: 100%;
}
</style>
