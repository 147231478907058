
import { defineComponent, reactive } from 'vue'

export default defineComponent({
  setup () {
    const state = reactive({
      topImage: require('@/assets/images/about/top.jpg')
    })
    return {
      state
    }
  }
})
