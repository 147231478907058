<template>
  <div
    id="contact"
    class="contact"
  >
    <div class="contact__wrapper">
      <h2
        class="contact__title"
      >
        お問い合わせ
      </h2>
      <div class="contact__section">
        <h3
          class="contact__section--title"
        >
          電話番号
        </h3>
        <div class="contact__section--inner">
          <a
            href="tel:0526718033"
            class="contact__tel"
          >
            052-671-8033
          </a>
        </div>
      </div>
      <div class="contact__section">
        <h3
          class="contact__section--title"
        >
          メールアドレス
        </h3>
        <div class="contact__section--inner">
          <span class="contact__mail">
            magurouokane@gmail.com
          </span>
        </div>
      </div>
      <div class="contact__section">
        <h3
          class="contact__section--title"
        >
          SNS
        </h3>
        <div class="contact__sns">
          <a
            :href="state.twitter.link"
            target="_blank"
          >
            <div class="contact__link">
              <div
                class="contact__link--box"
              >
                <img
                  :src="state.twitter.logo"
                  :alt="state.twitter.text"
                  class="contact__link--logo"
                >
              </div>
              <div class="contact__link--text">
                {{ state.twitter.text }}
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="contact__section">
        <div class="contact__note">
          LINEやビデオ会議サービスを利用してのオンライン商談も承っていますので、お気軽にご相談ください。
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue'

export default defineComponent({
  setup () {
    const state = reactive({
      twitter: {
        logo: require('@/assets/images/contact/twitter.png'),
        text: 'twitter',
        link: 'https://twitter.com/maguro_uokane'
      },
      qr: {
        src: require('@/assets/images/contact/qr.jpg')
      }
    })
    return {
      state
    }
  }
})
</script>

<style lang="scss" scoped>
.contact {
  width: 100%;
  &__wrapper {
    width: var(--contents-width);
    margin: 0 auto;
    padding-bottom: 50px;
    @include mq() {
      width: 100%;
      padding: 0 var(--paddig-side-space-sp) 50px;
    }
  }
  &__title {
    padding-bottom: 30px;
    font-size: var(--contents-title-size);
    font-weight: bold;
    color: var(--font-title-color);
  }
  &__section {
    width: 100%;
    &--title {
      padding-bottom: 15px;
      font-size: 20px;
      font-weight: bold;
      color: #333;
      @include mq() {
        font-size: 18px;
        padding-bottom: 10px;
      }
    }
    &--inner {
      padding-bottom: 30px;
    }
  }
  &__tel {
    color: var(--font-color);
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    pointer-events: none;
    @include mq() {
      pointer-events: initial;
    }
  }
  &__mail {
    color: var(--font-color);
    font-size: 16px;
  }
  &__sns {
    display: inline-block;
    margin-bottom: 30px;
    @include mq() {
      display: block;
    }
  }
  &__link {
    display: flex;
    width: 300px;
    height: 60px;
    align-items: center;
    border: 1px solid var(--color-twitter);
    border-radius: 5px;
    cursor: pointer;
    @include mq() {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
    &--box {
      padding: 10px 0 5px 0;
      text-align: center;
    }
    &--logo {
      display: inline-block;
      width: 51px;
      height: auto;
      padding-left: 15px;
    }
    &--text {
      display: inline;
      width: 230px;
      text-align: center;
      font-weight: bold;
      color: var(--color-twitter);
      @include mq() {
        width: 100%;
      }
    }
  }
  &__qr {
    width: 200px;
    @include mq() {
      margin: 0 auto;
    }
    &--image {
      width: 100%;
      object-fit: contain;
      border-radius: 10px;
    }
  }
  &__note {
    font-size: var(--contents-text-size);
    line-height: 1.5rem;
    &::before {
      content: "※"
    }
  }
}
</style>
