<template>
  <div class="main">
    <div class="main__wrapper">
      <About />
      <Products />
      <Howto />
      <Contact />
      <Information />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import About from '@/components/contents/About.vue'
import Products from '@/components/contents/Products.vue'
import Howto from '@/components/contents/Howto.vue'
import Contact from '@/components/contents/Contact.vue'
import Information from '@/components/contents/Information.vue'

export default defineComponent({
  name: 'Index',
  components: {
    About,
    Products,
    Howto,
    Contact,
    Information
  }
})
</script>

<style lang="scss" scoped>
.main {
  &__wrapper {
    min-height: 100%;
  }
}
</style>
