
import { defineComponent, reactive } from 'vue'

export default defineComponent({
  setup () {
    const state = reactive({
      list: [
        '温塩水を作る(40℃くらいのお湯1リットルに対して塩大さじ2杯)',
        'マグロを温塩水に沈めて表面の切り粉を綺麗に洗う',
        '5分〜10分そのまま解凍',
        '流水で表面の塩水を軽く洗い流す',
        'キッチンペーパーで水分をふき取る',
        'キッチンペーパーを巻いて冷蔵庫で2時間ほど解凍',
        'キッチンペーパーの上からラップを巻いて冷蔵庫で半日寝かす'
      ],
      listSimple: [
        'マグロをぬるま湯で洗う',
        'キッチンペーパーで水分をふき取る',
        '3〜4時間常温解凍',
        '完全に溶けたらキッチンペーパーを巻いて冷蔵庫で半日寝かす'
      ],
      listCut: [
        '大きなサクを食べやすい大きさのサクに切り分ける',
        '包丁は筋に対して垂直に入れる'
      ],
      images: {
        sample1: require('@/assets/images/howto/howto_01.jpg'),
        sample2: require('@/assets/images/howto/howto_02.jpg')
      }
    })
    return {
      state
    }
  }
})
