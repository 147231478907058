
import { defineComponent, reactive } from 'vue'

export default defineComponent({
  setup () {
    const state = reactive({
      list: [
        '生本マグロ(天然) ',
        '生インドマグロ(天然) ',
        '生メバチマグロ',
        '生キハダマグロ',
        '冷凍本マグロ(天然・蓄養)',
        '冷凍インドマグロ(天然)',
        '冷凍メバチマグロ',
        '生マカジキ',
        '冷凍マカジキ'
      ],
      images: [
        {
          name: '長崎産本マグロ',
          img: require('@/assets/images/products/honma_nagasaki.jpg'),
          alt: '長崎産本マグロ'
        },
        {
          name: 'ニュージーランド産インドマグロ',
          img: require('@/assets/images/products/india_nz.jpg'),
          alt: 'ニュージーランド産インドマグロ'
        },
        {
          name: '宮崎油津のキハダ上物',
          img: require('@/assets/images/products/kihada_miyazaki.jpg'),
          alt: '宮崎油津のキハダ上物'
        },
        {
          name: '和歌山勝浦産マカジキ',
          img: require('@/assets/images/products/makajiki_wakayama.jpg'),
          alt: '和歌山勝浦産マカジキ'
        }
      ]
    })
    const classReferenceList = (index: number) => {
      const blockEl = 'products__reference--list'
      return index % 2 === 0 ? `${blockEl}-left` : `${blockEl}-right`
    }
    return {
      state,
      classReferenceList
    }
  }
})
