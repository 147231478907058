
import { defineComponent, PropType, computed } from 'vue'
import { FooterOptions } from 'vue3-types'

export default defineComponent({
  props: {
    footerOptions: {
      type: Object as PropType<FooterOptions>,
      default: () => ({
        footerItems: [],
        copyright: {
          align: 'center'
        }
      })
    }
  },
  setup (props) {
    const items = computed(
      () => props.footerOptions.footerItems ?? []
    )
    const copyright = computed(
      () => props.footerOptions.copyright ?? {}
    )
    const classCopyrightAlign = computed(
      () => {
        const align = props.footerOptions.copyright.align ?? 'center'
        return `footer__copyright--${align}`
      }
    )
    return {
      items,
      copyright,
      classCopyrightAlign
    }
  }
})
