
import { defineComponent, PropType } from 'vue'
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import { HeaderOptions, FooterOptions } from 'vue3-types'

export default defineComponent({
  components: {
    AppHeader,
    AppFooter
  },
  props: {
    headerOptions: {
      type: Object as PropType<HeaderOptions>,
      default: () => ({})
    },
    footerOptions: {
      type: Object as PropType<FooterOptions>,
      default: () => ({})
    },
    isSp: {
      type: Boolean,
      default: false
    }
  }
})
