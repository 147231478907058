<template>
  <AppLayout
    :header-options="headerOptions"
    :footer-options="footerOptions"
    :is-sp="state.isSp"
  />
</template>

<script lang="ts">
import { defineComponent, reactive, onMounted, onBeforeUnmount } from 'vue'
import AppLayout from '@/layouts/AppLayout.vue'
import { HeaderOptions, FooterOptions } from 'vue3-types'

export default defineComponent({
  components: {
    AppLayout
  },
  setup () {
    const state = reactive({
      isSp: window.innerWidth < 1000
    })
    const headerOptions: HeaderOptions = {
      headerTitle: {
        text: '魚兼商店'
      },
      headerItems: [
        {
          text: '魚兼商店について',
          id: 'about'
        },
        {
          text: '取扱商品',
          id: 'products'
        },
        {
          text: '美味しい食べ方',
          id: 'howto'
        },
        {
          text: 'お問い合わせ',
          id: 'contact'
        },
        {
          text: '店舗情報',
          id: 'information'
        }
      ]
    }
    const footerOptions: FooterOptions = {
      footerItems: [],
      copyright: {
        text: '© 魚兼商店 All rights reserved.',
        align: 'end'
      }
    }
    const handleResize = () => {
      state.isSp = window.innerWidth < 800
    }
    onMounted(() => {
      window.addEventListener('resize', handleResize)
      window.addEventListener('orientationchange', handleResize)
    })
    onBeforeUnmount(() => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('orientationchange', handleResize)
    })
    return {
      state,
      headerOptions,
      footerOptions
    }
  }
})
</script>
