
import { defineComponent } from 'vue'

import About from '@/components/contents/About.vue'
import Products from '@/components/contents/Products.vue'
import Howto from '@/components/contents/Howto.vue'
import Contact from '@/components/contents/Contact.vue'
import Information from '@/components/contents/Information.vue'

export default defineComponent({
  name: 'Index',
  components: {
    About,
    Products,
    Howto,
    Contact,
    Information
  }
})
