<template>
  <div
    id="about"
    class="about"
  >
    <div class="about__wrapper">
      <div class="about__image-frame">
        <h1 class="about__title">
          <div class="about__title--sub">
            マグロ問屋
          </div>
          <div class="about__title--main">
            魚兼商店
          </div>
        </h1>
        <img
          :src="state.topImage"
          class="about__img"
        >
      </div>
      <div class="about__contents">
        <div class="about__contents--left">
          <h2 class="about__greeting">
            魚兼商店について
          </h2>
        </div>
        <div class="about__contents--right">
          <div class="about__text">
            マグロ一筋六十年。<br>
            はじめまして、魚兼商店と申します。<br>
            当店のモットーは、「安くて美味しいマグロ」。<br>
            シンプルですがこれが1番難しい。<br>
            高くて美味しいのは当たり前。<br>
            お値打ちで美味しいマグロは是非当店へ。<br>
          </div>
          <div class="about__text">
            愛知、岐阜、三重を中心に高級寿司店様、料亭様を中心に、フレンチ、イタリアンなどの洋食レストラン様へも商品を提供致しております。<br>
            どうぞ安心して当店のマグロをご賞味下さい。<br>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue'

export default defineComponent({
  setup () {
    const state = reactive({
      topImage: require('@/assets/images/about/top.jpg')
    })
    return {
      state
    }
  }
})
</script>

<style lang="scss" scoped>
.about {
  width: 100%;
  &__wrapper {
    width: 100%;
    padding-bottom: 50px;
  }
  &__image-frame {
    position: relative;
    width: var(--contents-width);
    margin: 0 auto;
    @include mq() {
      width: 100%;
    }
  }
  &__img {
    width: 100%;
    object-fit: contain;
  }
  &__title {
    position: absolute;
    top: 50px;
    left: 50px;
    width: 50%;
    height: 50%;
    @include mq() {
      top: 10px;
      left: 20px;
    }
    &--main {
      position: absolute;
      top: 45px;
      font-size: 48px;
      font-weight: bold;
      color: #FFF;
      @include mq() {
        top: 30px;
        font-size: 32px;
      }
    }
    &--sub {
      position: absolute;
      top: 20px;
      font-size: 18px;
      font-weight: bold;
      color: #FFF;
      @include mq() {
        top: 5px;
        font-size: 16px;
        width: 50%;
      }
    }
  }
  &__contents {
    display: flex;
    width: var(--contents-width);
    margin: 0 auto;
    padding-top: 50px;
    @include mq() {
      width: 100%;
      padding: 0 var(--paddig-side-space-sp);
      flex-direction: column;
    }
    &--left {
      width: 25%;
      @include mq() {
        width: 100%;
      }
    }
    &--right {
      width: 75%;
      @include mq() {
        width: 100%;
      }
    }
  }
  &__greeting {
    margin: auto;
    writing-mode: vertical-rl;
    text-orientation: upright;
    font-size: 32px;
    letter-spacing: 0.5rem;
    @include mq() {
      padding: 25px 0;
      writing-mode: horizontal-tb;
      font-size: 28px;
      text-align: center;
    }
  }
  &__text {
    padding: 0 80px 50px 0;
    font-size: 16px;
    line-height: 1.8rem;
    @include mq() {
      padding: 0 0 20px 0;
      font-size: var(--contents-text-size);
      line-height: 1.5rem;
      &:last-child {
        padding: 0;
      }
    }
  }
}
</style>
