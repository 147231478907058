<template>
  <div
    id="information"
    class="info"
  >
    <div class="info__wrapper">
      <h2
        class="info__title"
      >
        店舗情報
      </h2>
      <div class="info__container">
        <div class="info__detail">
          <div class="info__section">
            <h3 class="info__section--title">
              住所
            </h3>
            <div class="info__section--inner">
              <div class="info__address">
                愛知県名古屋市熱田区千代田町17-8
              </div>
              <div class="info__address">
                大名古屋食品卸センター 1番通り
              </div>
            </div>
            <div class="info__section--inner">
              <div class="info__address">
                地下鉄日比野駅3番出口から徒歩1分
              </div>
            </div>
          </div>
          <div class="info__section">
            <h3 class="info__section--title">
              営業日
            </h3>
            <div class="info__section--inner">
              <div class="info__business-day">
                名古屋中央卸売市場に準ずる
              </div>
              <div class="info__business-day">
                （※ 下記リンクの本場参照）
              </div>
              <div class="info__calendar">
                <a
                  href="https://www.city.nagoya.jp/kurashi/category/19-18-12-0-0-0-0-0-0-0.html"
                  target="_blank"
                  class="info__link"
                >
                  休開市カレンダー
                </a>
              </div>
            </div>
          </div>
          <div class="info__section">
            <h3 class="info__section--title">
              営業時間
            </h3>
            <div class="info__section--inner">
              <div class="info__business-hours">
                6:00 - 10:00
              </div>
              <div class="info__business-hours--note">
                （※ 営業時間は目安の時間となります）
              </div>
            </div>
          </div>
        </div>
        <div class="info__map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1631.433609860826!2d136.89239428572864!3d35.134986927975476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60037759dcd5b73d%3A0xa4c3190ed8fcb05c!2z44CSNDU2LTAwNzMg5oSb55-l55yM5ZCN5Y-k5bGL5biC54ax55Sw5Yy65Y2D5Luj55Sw55S677yR77yX4oiS77yYIOWkp-WQjeWPpOWxi-mjn-WTgeWNuOOCu-ODs-OCv-ODvA!5e0!3m2!1sja!2sjp!4v1615551356985!5m2!1sja!2sjp"
            width="100%"
            height="400"
            style="border:0;"
            allowfullscreen=""
            loading="lazy"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({})
</script>

<style lang="scss" scoped>
.info {
  width: 100%;
  &__wrapper {
    width: var(--contents-width);
    margin: 0 auto;
    padding-bottom: 50px;
    @include mq() {
      width: 100%;
      padding: 0 var(--paddig-side-space-sp) 50px;
    }
  }
  &__title {
    padding-bottom: 30px;
    font-size: var(--contents-title-size);
    font-weight: bold;
    color: var(--font-title-color);
  }
  &__container {
    display: flex;
    @include mq() {
      flex-direction: column;
    }
  }
  &__detail {
    width: 50%;
    padding-right: 10px;
    @include mq() {
      width: 100%;
      padding-right: 0;
    }
  }
  &__map {
    width: 50%;
    padding-left: 10px;
    @include mq() {
      width: 100%;
      padding-left: 0;
    }
  }
  &__section {
    width: 100%;
    &--title {
      padding-bottom: 15px;
      font-size: 20px;
      font-weight: bold;
      color: #333;
      @include mq() {
        font-size: 18px;
        padding-bottom: 10px;
      }
    }
    &--inner {
      padding-bottom: 30px;
    }
  }
  &__address {
    font-size: var(--contents-text-size);
    line-height: 1.5rem;
  }
  &__business-day {
    font-size: var(--contents-text-size);
    padding-bottom: 10px;
  }
  &__business-hours {
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 10px;
    &--note {
      font-size: var(--contents-text-size);
    }
  }
  &__calendar {
    padding: 10px 0;
  }
  &__link {
    color: #191970;
    font-size: 16px;
    font-weight: bold;
  }
}
</style>
