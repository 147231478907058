<template>
  <div class="header">
    <div class="header__inner">
      <ul class="header__items">
        <li
          v-for="(item, index) in items"
          :key="index"
          class="header__item"
        >
          <div
            v-if="item.id"
            class="header__link"
            @click="scroll(item.id)"
          >
            <div>
              {{ item.text }}
            </div>
            <div
              v-if="!isSp"
              class="header__text-en"
            >
              {{ item.textEn }}
            </div>
          </div>
          <a
            v-if="item.href"
            :href="item.href"
            :target="item.target"
            class="header__link"
          >
            <div>
              {{ item.text }}
            </div>
            <div
              v-if="!isSp"
              class="header__text-en"
            >
              {{ item.textEn }}
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, reactive, computed, onMounted } from 'vue'
import { HeaderOptions } from 'vue3-types'
import smoothscroll from 'smoothscroll-polyfill'

export default defineComponent({
  props: {
    headerOptions: {
      type: Object as PropType<HeaderOptions>,
      default: () => ({
        headerItems: []
      })
    },
    isSp: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const state = reactive({
      titleImage: null
    })
    const title = computed(
      () => props.headerOptions.headerTitle ?? {}
    )
    const items = computed(
      () => props.headerOptions.headerItems ?? []
    )
    onMounted(() => {
      smoothscroll.polyfill()
    })
    const scroll = (id: string) => {
      const el = document.getElementById(id)
      if (el) {
        const rect = el.getBoundingClientRect().top
        const offset = window.pageYOffset
        const gap = props.isSp ? 104 : 64 // header height
        const target = rect + offset - gap
        window.scrollTo({
          top: target,
          behavior: 'smooth'
        })
      }
    }
    return {
      state,
      title,
      items,
      scroll
    }
  }
})
</script>

<style lang="scss" scoped>
.header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--header-color);
  z-index: 1000;
  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    @include mq() {
      padding: 10px 10px 4px 10px;
    }
  }
  &__title {
    width: 10%;
    margin-right: 80px;
    text-align: center;
    @include mq() {
      width: 25%;
      padding-right: 5px;
      margin-right: 0;
    }
  }
  &__logo {
    height: 60px;
    object-fit: cover;
    @include mq() {
      width: 100%;
      height: 100%;
      max-height: 60px;
      object-fit: contain;
    }
  }
  &__items {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1000px;
    padding: 10px 0;
    list-style-type: none;
    cursor: pointer;
    @include mq() {
      min-width: 0;
      padding: 0;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }
  &__item {
    padding: 0 20px;
    font-size: 16px;
    transition: 0.5s ;
    @include mq() {
      min-width: 33%;
      padding: 8px 10px 16px 10px;
    }
    @include mq(xs) {
      font-size: 12px;
      font-weight: bold;
    }
    &:hover {
      opacity: 0.5;
    }
  }
  &__link {
    color: var(--font-color);
    text-decoration: none;
    text-align: center;
  }
  &__text-en {
    font-size: 12px;
    color: var(--font-pale-color);
    text-decoration: none;
    text-align: center;
    &:visited, :active, :hover {
      color: var(--font-pale-color);
    }
  }
}
</style>
